import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { checkForAuthError } from "../checkAuth";
import {
  SearchPipelinesV1PipelinesSearchPostApiArg,
  SearchPipelinesV1PipelinesSearchPostApiResponse,
  SearchInstancesV1InstancesSearchPostApiArg,
  SearchInstancesV1InstancesSearchPostApiResponse,
  DeployIdeV1InstancesDeployPostApiResponse,
  DeploymentCreateRequest,
} from "./ideService";

const proxyPath = "/api/proxy/ide";

const baseQuery = (baseUrl) => {
  const baseQuery = fetchBaseQuery({
    // location.origin needed for msw to work
    baseUrl: new URL(baseUrl, location.origin).href,
  });

  return async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    checkForAuthError(result);

    return result;
  };
};

const createAuthenticatedQuery = (username, password) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: new URL(proxyPath, location.origin).href,
    prepareHeaders: (headers) => {
      headers.set("Authorization", "Basic " + btoa(`${username}:${password}`));
      return headers;
    },
  });

  return async (args, api, extraOptions) => {
    // Use the baseQuery function
    return baseQuery(args, api, extraOptions);
  };
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  reducerPath: "ideApi",
  baseQuery: baseQuery(proxyPath),
  endpoints: (build) => ({
    // create a query version of search piplines so we can use refetch behaviour
    searchPipelinesV1PipelinesSearchPostCustom: build.query<
      SearchPipelinesV1PipelinesSearchPostApiResponse,
      SearchPipelinesV1PipelinesSearchPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/pipelines/search`,
        method: "POST",
        body: queryArg.pipelineFilterRequest,
      }),
    }),
    searchInstances: build.query<
      SearchInstancesV1InstancesSearchPostApiResponse,
      SearchInstancesV1InstancesSearchPostApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/instances/search`,
        method: "POST",
        body: queryArg.instanceFilterRequest,
      }),
    }),
    deployIde: build.mutation<
      DeployIdeV1InstancesDeployPostApiResponse,
      {
        username: string;
        password: string;
      } & DeploymentCreateRequest
    >({
      queryFn(arg, api, extraOptions) {
        const { username, password, ...ide } = arg;

        const authenticatedQuery = createAuthenticatedQuery(username, password);

        return authenticatedQuery(
          { url: "/v1/instances/deploy", method: "POST", body: ide },
          api,
          extraOptions
        );
      },
    }),
  }),
});

export const {
  useSearchPipelinesV1PipelinesSearchPostCustomQuery,
  useSearchInstancesQuery,
  useDeployIdeMutation,
} = emptySplitApi;
