import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "../components/Modal/Modal";
import Button from "../components/Button/Button";
import { Flex, TextField, Select, Text, Box } from "@radix-ui/themes";

import { useDeployIdeMutation } from "../app/api/ide/ideApiBase";

const validPlatforms = ["mobilev2", "backendv2", "webv2"];
const validCloudProviders = ["az", "aws"];

const username = "IDE_INTERNAL";
const password = "";

const LOCAL_STORAGE_KEY = "deployIdeModal";

type DeployFormValues = {
  email: string;
  projectId: string;
  platform: string;
  cloudProvider: string;
  region: string;
  apiUser: string;
  token: string;
};

type Props = {
  open: boolean;
  close: () => void;
};

export const DeployIdeModal = (props: Props) => {
  const { open, close } = props;

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<DeployFormValues>();

  const [deploy, { isLoading, isError, isSuccess }] = useDeployIdeMutation();

  const [platform, setPlatform] = useState(validPlatforms[0]);
  const [cloudProvider, setCloudProvider] = useState(validCloudProviders[0]);

  const saveFormData = (data: DeployFormValues) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
  };

  const loadFormData = () => {
    const savedData = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      Object.keys(parsedData).forEach((key) => {
        setValue(key as keyof DeployFormValues, parsedData[key]);
      });
    }
  };

  useEffect(() => {
    if (open) {
      loadFormData();
    }
  }, [open]);

  useEffect(() => {
    const subscription = watch((data: any) => {
      saveFormData(data);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data: DeployFormValues) => {
    deploy({
      username,
      password,
      ide: {
        email: data.email,
        platform: platform,
        project_id: data.projectId,
        cloud_provider: cloudProvider,
        region: data.region,
        token: data.token,
        api_user: data.apiUser,
      } as any,
    });
  };

  return (
    <Modal open={open} close={close}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" gap="4">
          {/* Email Field */}
          <Flex direction="column" gap="2">
            <Text size="2" weight="bold">
              Email Address
            </Text>
            <TextField.Input
              {...register("email", { required: true })}
              placeholder="Email address..."
              type="email"
            />
            {errors.email && <Text color="red">Email is required</Text>}
          </Flex>

          {/* Project ID */}
          <Flex direction="column" gap="2">
            <Text size="2" weight="bold">
              Project ID
            </Text>
            <TextField.Input
              {...register("projectId", { required: true })}
              placeholder="Project ID..."
            />
            {errors.projectId && (
              <Text color="red">Project ID is required</Text>
            )}
          </Flex>

          {/* Platform Selection */}
          <Flex direction="row" gap="4">
            <Flex direction="column" gap="2" style={{ flex: 1 }}>
              <Text size="2" weight="bold">
                Platform
              </Text>
              <Select.Root value={platform} onValueChange={setPlatform}>
                <Select.Trigger style={{ width: "100%" }}>
                  {platform}
                </Select.Trigger>
                <Select.Content>
                  {validPlatforms.map((platform) => (
                    <Select.Item key={platform} value={platform}>
                      {platform}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
              {errors.platform && <Text color="red">Platform is required</Text>}
            </Flex>

            {/* Cloud Provider Selection */}
            <Flex direction="column" gap="2" style={{ flex: 1 }}>
              <Text size="2" weight="bold">
                Cloud Provider
              </Text>
              <Select.Root
                value={cloudProvider}
                onValueChange={setCloudProvider}
              >
                <Select.Trigger style={{ width: "100%" }}>
                  {cloudProvider}
                </Select.Trigger>
                <Select.Content>
                  {validCloudProviders.map((provider) => (
                    <Select.Item key={provider} value={provider}>
                      {provider}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
              {errors.cloudProvider && (
                <Text color="red">Cloud Provider is required</Text>
              )}
            </Flex>
          </Flex>

          {/* Region Field */}
          <Flex direction="column" gap="2">
            <Text size="2" weight="bold">
              Region
            </Text>
            <TextField.Input
              {...register("region", { required: true })}
              placeholder="Region..."
            />
            {errors.region && <Text color="red">Region is required</Text>}
          </Flex>

          {/* API User */}
          <Flex direction="column" gap="2">
            <Text size="2" weight="bold">
              API User
            </Text>
            <TextField.Input
              {...register("apiUser")}
              placeholder="API user..."
            />
          </Flex>

          {/* Token */}
          <Flex direction="column" gap="2">
            <Text size="2" weight="bold">
              Token
            </Text>
            <TextField.Input {...register("token")} placeholder="Token..." />
          </Flex>

          {/* Submit Button */}
          <Box>
            <Button type="submit" pending={isLoading}>
              Deploy
            </Button>
          </Box>

          {/* Success/Error Message */}
          {isSuccess && <Text color="green">Request was successful!</Text>}
          {isError && (
            <Text color="red">Error deploying IDE. Please try again.</Text>
          )}
        </Flex>
      </form>
    </Modal>
  );
};
