import React, { useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  HamburgerMenuIcon,
  MagnifyingGlassIcon,
  PersonIcon,
  DoubleArrowUpIcon,
  MixIcon,
  GlobeIcon,
  CubeIcon,
  ActivityLogIcon,
  ClockIcon,
  GearIcon,
  ArchiveIcon,
  CounterClockwiseClockIcon,
} from "@radix-ui/react-icons";
import { Flex } from "@radix-ui/themes";
import { useOnClickOutside } from "usehooks-ts";
import { Link } from "react-router-dom";
import intersperse from "intersperse";

import { router } from "../../App";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  layoutStateSelector,
  setMenuOpen,
  setRecentlyVisitedOpen,
} from "../../app/layoutReducer";
import axios from "../../axios";
import { Menu } from "./Menu";
import styles from "./Layout.module.scss";
import {
  FEATURE_FLAG_TEST,
  FEATURE_FLAG_COUNTRY_CODES,
  FEATURE_FLAG_CLUSTERS,
  FEATURE_FLAG_CLUSTER_HEALTH,
  FEATURE_FLAG_REPORTS,
} from "../../app/featureFlagsReducer";
import { FeatureFlag } from "../FeatureFlag/FeatureFlag";
import { RecentlyVisited } from "../../RecentlyVisited/RecentlyVisitedPanel";

export function Layout() {
  const state = useAppSelector(layoutStateSelector);

  const dispatch = useAppDispatch();

  const location = useLocation();

  useEffect(() => {
    // close menu on route change
    dispatch(setMenuOpen(false));
  }, [location]);

  const menuRef = useRef(null);
  const recentlyUsedPanelRef = useRef(null);

  useOnClickOutside(menuRef, () => {
    if (state.menuOpen) {
      dispatch(setMenuOpen(false));
    }
  });

  useOnClickOutside(recentlyUsedPanelRef, () => {
    if (state.recentlyVisitedOpen) {
      dispatch(setRecentlyVisitedOpen(false));
    }
  });

  const breadcumbs = state.breadcrumbs.map((crumb, idx) => {
    if (crumb.type === "url") {
      return (
        <Link
          to={crumb.url}
          className={styles.breadcrumb}
          key={crumb.text + crumb.url + idx}
        >
          {crumb.text}
        </Link>
      );
    }

    return <div key={crumb.text + idx}>{crumb.text}</div>;
  });

  const breadcrumbsWithSpacers = intersperse(
    breadcumbs,
    <div className={styles.spacer} key="bread">
      -
    </div>
  );

  const onLogout = () => {
    axios.post("/public-api/logout");

    // Clear the token field from deployIdeModal in localStorage
    const deployFormData = localStorage.getItem("deployIdeModal");
    if (deployFormData) {
      const parsedData = JSON.parse(deployFormData);
      delete parsedData.token;
      localStorage.setItem("deployIdeForm", JSON.stringify(parsedData));
    }

    router.navigate("/login");
  };

  return (
    <div>
      <div className={styles.topNav}>
        <div
          className={styles.menuTrigger}
          onClick={() => dispatch(setMenuOpen(true))}
        >
          <HamburgerMenuIcon
            width="24"
            height="24"
            className={styles.menuIcon}
          />
        </div>
        <div className={styles.breadcrumbs}>{breadcrumbsWithSpacers}</div>
      </div>
      <Menu.Panel
        isOpen={state.menuOpen}
        setOpen={(open) => dispatch(setMenuOpen(open))}
        footer={
          <div onClick={onLogout} className={styles.logout}>
            Log out
          </div>
        }
        refProp={menuRef}
      >
        <Flex direction="column" gap="4">
          <Menu.Logo />
          <Menu.ItemCol>
            <Menu.Item
              to="/"
              text="Search instances"
              active={false}
              icon={<MagnifyingGlassIcon />}
            />
            <FeatureFlag flagName={FEATURE_FLAG_CLUSTERS}>
              <Menu.Item
                to="/clusters"
                text="Clusters"
                active={false}
                icon={<CubeIcon />}
              />
            </FeatureFlag>
            <Menu.Item
              to="/capacity-partners"
              text="Capacity partners"
              active={false}
              icon={<DoubleArrowUpIcon />}
            />
            <FeatureFlag flagName={FEATURE_FLAG_CLUSTER_HEALTH}>
              <Menu.Item
                to="/health-overview"
                text="Health Overview"
                active={false}
                icon={<ActivityLogIcon />}
              />
            </FeatureFlag>
            <Menu.Item
              to="/api-users"
              text="API users"
              active={false}
              icon={<MixIcon />}
            />
            <Menu.Item
              to="/admin-users"
              text="Admin users"
              active={false}
              icon={<PersonIcon />}
            />
            <FeatureFlag flagName={FEATURE_FLAG_COUNTRY_CODES}>
              <Menu.Item
                to="/country-codes"
                text="Country codes"
                active={false}
                icon={<GlobeIcon />}
              />
            </FeatureFlag>
            <FeatureFlag flagName={FEATURE_FLAG_REPORTS}>
              <Menu.Item
                to="/reports"
                text="Reports"
                active={false}
                icon={<ArchiveIcon />}
              />
            </FeatureFlag>
            <FeatureFlag flagName={FEATURE_FLAG_TEST}>
              <div data-testid="test-flag">
                Unit test dependancy do not remove
              </div>
            </FeatureFlag>
            <Menu.Divider />
            <Menu.Item
              to="/chat"
              text="Recent chats"
              active={false}
              icon={<ClockIcon />}
            />
            <Menu.Item
              to="/chat-control-panel"
              text="Chat control panel"
              active={false}
              icon={<GearIcon />}
            />
          </Menu.ItemCol>
        </Flex>
      </Menu.Panel>

      <Outlet />

      <div
        className={styles.recentlyVisitedTrigger}
        onClick={() =>
          dispatch(setRecentlyVisitedOpen(!state.recentlyVisitedOpen))
        }
        ref={recentlyUsedPanelRef}
      >
        <CounterClockwiseClockIcon
          width="20"
          height="20"
          className={styles.menuIcon}
        />
        {state.recentlyVisitedOpen && (
          <div className={styles.recentlyVisited}>
            <RecentlyVisited />
          </div>
        )}
      </div>
    </div>
  );
}
