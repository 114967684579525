import { createAction, createReducer } from "@reduxjs/toolkit";
import { TRootState } from "./store";
import { TPublicVars } from "../../../server/src/types";

export const FEATURE_FLAG_TEST = "FEATURE_FLAG_TEST";
export const FEATURE_FLAG_COUNTRY_CODES = "FEATURE_FLAG_COUNTRY_CODES";
export const FEATURE_FLAG_CLUSTERS = "FEATURE_FLAG_CLUSTERS";
export const FEATURE_FLAG_CLUSTER_HEALTH = "FEATURE_FLAG_CLUSTER_HEALTH";
export const FEATURE_FLAG_REPORTS = "FEATURE_FLAG_REPORTS";
export const FEATURE_FLAG_DEPLOY_IDE = "FEATURE_FLAG_DEPLOY_IDE";

export const featureFlagNames = [
  FEATURE_FLAG_TEST,
  FEATURE_FLAG_COUNTRY_CODES,
  FEATURE_FLAG_CLUSTERS,
  FEATURE_FLAG_CLUSTER_HEALTH,
  FEATURE_FLAG_REPORTS,
  FEATURE_FLAG_DEPLOY_IDE,
] as const;

export type TFeatureFlagNames = (typeof featureFlagNames)[number];

export type TFeatureFlags = {
  [key: string]: boolean;
};

type TState = {
  flags: TFeatureFlags;
};

const initialState: TState = {
  flags: {},
};

export const gotFeatureFlags = createAction<TFeatureFlags>(
  "featureFlags/gotFeatureFlags"
);

export const featureFlagReducer = createReducer(initialState, (builder) => {
  return builder.addCase(gotFeatureFlags, (state, action) => {
    state.flags = action.payload;
  });
});

export function extractDevModeFeatureFlags(
  flagNames: readonly string[],
  env: { [key: string]: string }
): TFeatureFlags {
  const flags = {};

  for (const name of flagNames) {
    flags[name] = env[`VITE_${name}`] === "true";
  }

  return flags;
}

export function extractPublicVarsFeatureFlags(
  flagNames: readonly string[],
  publicVars: TPublicVars
): TFeatureFlags {
  const flags = {};

  for (const name of flagNames) {
    flags[name] = publicVars[name] === true;
  }

  return flags;
}

export const featureFlagSelector = (state: TRootState) =>
  state.featureFlagReducer;
